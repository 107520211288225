import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import GravityForm from 'components/shared/GravityForm'

const StyledContact = styled.section`
  position: relative;
  margin-top: -150px;
  margin-bottom: -75px;
  z-index: 1;

  & h2 {
    line-height: ${(props) => props.theme.font.size.l} !important;
    text-transform: unset;

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size.xm} !important;
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.m} !important;
    }
  }
`

const ContactBlock = styled.div`
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const ContactBlockInfo = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  border-radius: 25px;
`

const ContactBlockInfoContent = styled(ParseContent)`
  & h2:first-child {
    margin-bottom: 3rem;
  }

  & .gatsby-image-wrapper > div > img,
  & [aria-hidden='true'] {
    display: none !important;
  }

  & img {
    object-fit: contain !important;
  }

  & a {
    color: ${(props) => props.theme.color.text.purple};

    &:hover {
      color: ${(props) => props.theme.color.text.purple};
    }
  }
`

interface ContactThanksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ContactThanks
}

const ContactThanks: React.FC<ContactThanksProps> = ({ fields, title }) => {

  // grab the param from url
  const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : {});
  const email = urlParams.get('email');
  const firstname = urlParams.get('firtsname');
  const lastname = urlParams.get('lastname');

  return (
    <StyledContact>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <ContactBlock>
              <div className="row mx-0">
                <div className="col-md-6 pl-lg-4 py-4 py-lg-5">
                  <div className="px-lg-5">
                    <h2>{fields?.title}</h2>
                    <div className="mt-4">
                      <ParseContent content={fields.description} />
                    </div>
                  </div>
                </div>
                <ContactBlockInfo className="col-md-6 pr-lg-4 py-4 py-lg-5">
                  <div className="px-lg-5">
                    <ContactBlockInfoContent
                      content={
                        fields.contactDescription
                      }
                    />
                  </div>
                </ContactBlockInfo>
              </div>
            </ContactBlock>
          </div>
        </div>
      </div>
    </StyledContact>
  )
}

export default ContactThanks
